import React, { useState, useEffect, useRef } from "react";
import Modal from "react-bootstrap/Modal";
import classes from "./EditCompanyModal.module.css";
import useOnClickOutside from "../useCustomHooks/useOnClickOutside";

import { GrImage, GrList, GrZoomIn, GrZoomOut } from "react-icons/gr";
import { AiOutlineCloseCircle, AiOutlineClose } from "react-icons/ai";
import { ImCross } from "react-icons/im";
import { curr } from "../Json files/Currency";
import { states } from "../Json files/States";
import Loader from "react-loader-spinner";
import EventInputs from "../../Components/Events/EventInputs";
import TextEditor from "../../Components/TextEditor";

const EditJobModal = (props) => {
  const handleClose = () => props.setShow(false);
  const [seoUrl, setSeoUrl] = useState("");
  const [seoUrlValidationMessage, setSeoUrlValidationMessage] = useState("");
  const [isSeoUrlValid, setIsSeoUrlValid] = useState(false);
  const [isSeoUrlEditable, setIsSeoUrlEditable] = useState(true);
  const [datePosted, setDatePosted] = useState(""); // Example date
  const [companyName, setCompanyName] = useState(props.companyName || "");
  const latestRequestId = useRef(0);
  const controllerRef = useRef(null);
  const [jobApplicationDetail, setJobApplicationDetail] = useState("");
  const [jobHtmlApplicationDetail, setJobHtmlApplicationDetail] = useState("");
  const [positionTitle, setPositionTitle] = useState("");
  const [jobFunction, setJobFunction] = useState("");
  const [level, setLevel] = useState("");
  const [jobDescription, setJobDescription] = useState("");
  const [jobHtmlDescription, setJobHtmlDescription] = useState("");
  const [minSalary, setMinSalary] = useState("");
  const [maxSalary, setMaxSalary] = useState("");
  const [email, setEmail] = useState("");
  const [employementType, setEmployementType] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [currency, setCurrency] = useState("INR");
  const [industry, setIndustry] = useState("");
  const [streetAddress, setStreetAddress] = useState("");
  const [region, setRegion] = useState("");
  const [externalApplyUrl, setExternalApplyUrl] = useState("");
  const [locations, setLocations] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [address, setAddress] = useState("");
  const [walkInStartTime, setWalkInStartTime] = useState("");
  const [walkInEndTime, setWalkInEndTime] = useState("");
  const [selectedLocationsId, setSelectedLocationsId] = useState([]);
  const [selectedLocationsName, setSelectedLocationsName] = useState([]);
  const [loading, setLoading] = useState(false);
  const [adDetailImage, setAdDetailImage] = useState("");
  const [isGovtJob, setIsGovtJob] = useState(false);

  const [minExp, setMinExp] = useState(props.data.minExperience);
  const [maxExp, setMaxExp] = useState(props.data.maxExperience);
  const [skills, setSkills] = useState(props.data.skills);
  const [storyTitle, setStoryTitle] = useState(props.companyName);
  const [storyDescription, setStoryDescription] = useState("");
  const [storyHeadingLast, setStoryHeadingLast] = useState("");
  const [storyPageUrl, setStoryPageUrl] = useState("");
  const [storyImageAltTag, setStoryImageAltTag] = useState("");
  const [storyImage, setStoryImage] = useState("");
  const [webstoryDetailUUID, setWebstoryDetailUUID] = useState("");
  const [isStoryLive, setIsStoryLive] = useState(false);
  const [newStoryImage, setNewStoryImage] = useState(
    props.webstorySummaryData.ImageUrl
  );

  const generateSeoUrl = () => {
    // Format date as "MMM-yyyy"
    const formattedDate = new Date(datePosted || new Date())
      .toLocaleDateString("en-US", { month: "short", year: "2-digit" })
      .toLowerCase()
      .replace(/\s+/g, "-");

    // Helper function to clean strings and provide default fallback
    const cleanString = (str, fallback = "unknown") =>
      str
        ?.replace(/[^\w\s-]/g, " ") // Remove non-alphanumeric except dashes
        .replace(/\s+/g, "-")       // Replace spaces with dashes
        .replace(/^-+|-+$/g, "")    // Trim leading/trailing dashes
        .toLowerCase() || fallback; // Fallback if string is empty or null

    // Clean components and provide default values if necessary
    const cleanedPositionTitle = cleanString(positionTitle, "position");
    const cleanedCompanyName = cleanString(companyName, "company");
    const cleanedLocations = cleanString(selectedLocationsName.join("-"), "location");

    // Generate SEO URL and remove any accidental double slashes
    const seoURL = `/new-jobs/${formattedDate}/${cleanedPositionTitle}/${cleanedCompanyName}/${cleanedLocations}`
      .replace(/\/\/+/g, "/"); // Replace any double slashes with single slash

    return seoURL;
  };

  // Handle SEO URL validation
  // const handleSeoUrlValidation = (value) => {
  //   // Basic SEO URL validation regex
  //   const regex = /^\/new-jobs\/[a-z0-9-]+\/[a-z0-9-]+\/[a-z0-9-]+\/[a-z0-9-]+$/;
  //   if (regex.test(value)) {
  //     setIsSeoUrlValid(true);
  //     setSeoUrlValidationMessage("SEO URL is valid.");
  //   } else {
  //     setIsSeoUrlValid(false);
  //     setSeoUrlValidationMessage(
  //       "Invalid SEO URL. Ensure it matches the pattern /new-jobs/{date}/{position}/{company}/{location}."
  //     );
  //   }
  // };

  const handleSeoUrlValidation = async (value) => {
    // Ensure required fields are present
    if (!positionTitle || !companyName || selectedLocationsName.length === 0) {
      setSeoUrlValidationMessage(
        "Position Title, Company Name, and Location are required to generate a valid SEO URL."
      );
      setIsSeoUrlValid(false);
      return;
    }

    // API call to check if SEO URL exists
    const requestBody = { SeoURL: value };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token 77927b69bb144b065ca11bf2a9d452819cd852db",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    };

    try {
      const response = await fetch(
        `${global.apiLink}/adminapi/CheckSeoURLinLatestJobs`,
        requestOptions
      );
      const result = await response.json();

      if (result.status_code === 200 && result.status === "SUCCESS") {
        if (result.IsSeoURLExist) {
          setSeoUrlValidationMessage("SEO URL already exists. Please modify it.");
          setIsSeoUrlValid(false);
        } else {
          setSeoUrlValidationMessage("SEO URL is valid.");
          setIsSeoUrlValid(true);
        }
      } else {
        setSeoUrlValidationMessage("Invalid SEO URL format.");
        setIsSeoUrlValid(false);
      }
    } catch (error) {
      setSeoUrlValidationMessage("Error validating SEO URL.");
      setIsSeoUrlValid(false);
    }
  };

  const generateAndValidateSeoUrl = () => {
    if (!positionTitle || !companyName || selectedLocationsName.length === 0) {
      setSeoUrlValidationMessage(
        "Position Title, Company Name, and Location are required to generate a valid SEO URL."
      );
      setIsSeoUrlValid(false);
      return;
    }

    const generatedUrl = generateSeoUrl();
    setSeoUrl(generatedUrl);

    // Validate the generated SEO URL
    handleSeoUrlValidation(generatedUrl);
  };
  const employmentList = [
    {
      type: "Full-Time",
    },
    {
      type: "Part-Time",
    },
    {
      type: "Contract",
    },
    {
      type: "Temporary",
    },
    {
      type: "Seasonsl",
    },
    {
      type: "Internship",
    },
  ];

  const removeSpacesWithHyphens = (str) => {
    return str
      ?.replace(/[^\w\s-]/g, "-")
      .replace(/\s+/g, "-")
      .replace(/--+/g, "-")
      .replace(/^-/, "")
      .replace(/^-+|-+$|(-)+/g, "$1")
      .toLowerCase()
      .trim();
  };

  // const adDetailsApi = () => {
  //     setLoading(true);
  //     var myHeaders = new Headers();
  //     myHeaders.append(
  //         "Authorization",
  //         "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
  //     );
  //     myHeaders.append("Content-Type", "application/json");

  //     var raw = JSON.stringify({
  //         id: "664639",
  //     });

  //     var requestOptions = {
  //         body: raw,
  //         method: "POST",
  //         headers: myHeaders,
  //         redirect: "follow",
  //     };

  //     fetch(global.apiLink+"/adminapi/ad-details", requestOptions)
  //         .then((response) => response.json())
  //         .then((result) => {
  //             setAdDetailImage(result.data.imageUrl);
  //             setJobApplicationDetail(result.data.applicationDetails);

  //         })
  //         .catch((error) => console.log("error", error));
  // };

  const findLocationIdHandler = (locationName) => {
    // console.log(locationName + "fff" + selectedLocationsId)

    if (selectedLocationsName.includes(locationName)) {
      return;
    }
    props.locationList.find((val) => {
      if (locationName === val.locName) {
        setSelectedLocationsId((preLocationId) => {
          return [...preLocationId, val.id];
        });
      }
    });
  };

  const handleSeoUrlChange = (value) => {
    setSeoUrl(value);
    handleSeoUrlValidation(value);
  };

  // Auto-generate SEO URL when dependent fields change
  useEffect(() => {
    // Auto-generate and validate SEO URL when dependent fields change
    generateAndValidateSeoUrl();
  }, [positionTitle, companyName, selectedLocationsName, datePosted]);


  const addLocationHandler = (selectedLocation) => {

    if (selectedLocationsName.includes(selectedLocation)) {
      return;
    }
    setSelectedLocationsName((preLocation) => {
      return [...preLocation, selectedLocation];
    });
  };
  useEffect(() => {

  }, [selectedLocationsName, selectedLocationsId]);
  //delete function for delete location box

  const deleteLocationName = (id) => {
    setSelectedLocationsName((preLocation) => {
      return preLocation.filter((item, index) => {
        return index !== id;
      });
    });
  };
  const deleteLocationId = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      printAdId: props.data.id,
      locationId: selectedLocationsId[id],
    });

    var requestOptions = {
      body: raw,
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/deleteLocationOfPrintAds", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS") {
          props.getAdsListByOrderNumber();
        }
        // else {
        //     alert("Job could not be updated")
        // }
      })
      .catch((error) => alert("location is not deleted"))
      .finally(() => {
        setLoading(false);
      });
    setSelectedLocationsId((preLocationId) => {
      return preLocationId.filter((item, index) => {
        return index !== id;
      });
    });
  };
  const userEmail = sessionStorage.getItem("googleBiEmail");
  const revalidatePage = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    let path = seoUrl.includes("https://timesascent.com")
      ? seoUrl.split("https://timesascent.com")[1]
      : `${seoUrl}`;



    fetch(
      `https://timesascent.com/api/revalidate?path=${path}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  const updateAdsByPrintAdsId = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: props.data.id,
      jobHtmlDescription: jobHtmlDescription,
      applicationHtmlDetails: jobHtmlApplicationDetail,
      applicationDetails: jobApplicationDetail,
      positionTitle: positionTitle,
      jobCategory: jobFunction,
      SeoURL: seoUrl,
      level: level,
      jobDescription: jobDescription,
      minCtc: minSalary,
      maxCtc: maxSalary,
      email: email,
      EmploymentType: employementType,
      PostalCode: postalCode,
      Currency: minSalary || maxSalary ? currency : "-",
      industry: industry,
      StreetAddress: streetAddress,
      AddressRegion: region,
      externalApplyUrl: externalApplyUrl,
      location: selectedLocationsId.join(","),
      WalkinStartDate: startDate,
      WalkinEndDate: endDate,
      WalkinAddress: address,
      governmentJob: isGovtJob ? "1" : "0",
      WalkinTime: `${walkInStartTime}-${walkInEndTime}`,
      iswalkin:
        startDate || endDate || address || walkInStartTime || walkInEndTime
          ? "1"
          : "0",
      userEmail: userEmail,
      companyId: props.companyId,
      skills: skills,
      minExperience: minExp,
      maxExperience: maxExp,
    });

    var requestOptions = {
      body: raw,
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(`${global.apiLink}/adminapi/updateAdsByPrintAdsId`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS") {
          revalidatePage();
          props.getAdsListByOrderNumber();
          handleClose();
        } else {
          alert("Job could not be updated");
        }
      })
      .catch((error) => alert("Job could not be updated"))
      .finally(() => {
        setLoading(false);
      });
  };

  const addJobWebstoryDetails = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();

    formdata.append(
      "WebStoriesSummaryID",
      props.webstorySummaryData.WebStoriesSummaryID
    );
    formdata.append("JobId", props.data.id);
    formdata.append("TitleType", storyTitle);
    formdata.append("DescriptionType", storyDescription);
    formdata.append("AltTag", storyImageAltTag);
    formdata.append("IsLive", isStoryLive ? "1" : "0");
    formdata.append("Url", storyPageUrl);
    formdata.append("HeadText", storyHeadingLast);
    formdata.append("Location", selectedLocationsName.join(","));
    if (storyImage) {
      formdata.append("DImage", storyImage);
    }

    var requestOptions = {
      body: formdata,
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/AddJobsWebstoriesDetail",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code == 200) {
          props.getAdsListByOrderNumber();
          handleClose();
        } else {
          alert("Job webstory could not be updated");
        }
      })
      .catch((error) => alert("Job webstory could not be updated"))
      .finally(() => {
        setLoading(false);
      });
  };

  const updateJobWebstoryDetails = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();

    formdata.append(
      "WebStoriesSummaryID",
      props.webstorySummaryData.WebStoriesSummaryID
    );
    formdata.append("WebStoriesDetailID", webstoryDetailUUID);
    formdata.append("JobId", props.data.id);
    formdata.append("TitleType", storyTitle);
    formdata.append("DescriptionType", storyDescription);
    formdata.append("AltTag", storyImageAltTag);
    formdata.append("IsLive", isStoryLive ? "1" : "0");
    formdata.append("Url", storyPageUrl);
    formdata.append("HeadText", storyHeadingLast);
    formdata.append("Location", selectedLocationsName.join(","));
    if (storyImage) {
      formdata.append("DImage", storyImage);
    }

    var requestOptions = {
      body: formdata,
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/UpdateJobsWebstoriesDetails",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code == 200) {
          props.getAdsListByOrderNumber();
          handleClose();
        } else {
          alert("Job webstory could not be updated");
        }
      })
      .catch((error) => alert("Job webstory could not be updated"))
      .finally(() => {
        setLoading(false);
      });
  };

  const getJobWebstoryDetailsByJobId = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 4YzpGk8Vf8IcxzxQ68teZ2KM5iX8SktW5RN4iz92"
    );

    var formdata = new FormData();
    formdata.append("JobId", props.data.id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/v1/api/apiTimes/GetJobsWebstoriesDetailsByJobID",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "Success" && result.status_code == 200) {
          if (result.data) {
            setStoryTitle(result.data.TitleType);
            setStoryDescription(result.data.DescriptionType);
            setStoryHeadingLast(result.data.HeadText);
            setStoryPageUrl(result.data.Url);
            setStoryImageAltTag(result.data.AltTag);
            setIsStoryLive(result.data.IsLive == "1" ? true : false);
            setWebstoryDetailUUID(result.data.WebStoriesDetailID);
            if (result.data.DImage) {
              setNewStoryImage(result.data.DImage);
            }
          }
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    // adDetailsApi();
  }, []);
  useEffect(() => {

    if (props.show) {
      setAdDetailImage(props.adDetailImage);
      if (!props.data) {
        return;
      }
      setJobDescription(props.data.jobDescription);

      setJobApplicationDetail(props.data.applicationDetails);
      setPositionTitle(props.data.positionTitle);
      setJobHtmlApplicationDetail(props.data.applicationHtmlDetails);
      setJobHtmlDescription(props.data.jobHtmlDescription);
      setStoryDescription(props.data.positionTitle);
      setStoryImageAltTag(props.data.positionTitle);
      setJobFunction(props.data.jobCategory);
      setLevel(props.data.level);

      setMinSalary(props.data.minCtc);
      setMaxSalary(props.data.maxCtc);
      setEmail(props.data.email);
      setIndustry(props.data.industry);
      setEmployementType(props.data.EmploymentType);
      setPostalCode(props.data.PostalCode);
      setCurrency(props.data.Currency);
      setStreetAddress(props.data.StreetAddress);
      setRegion(props.data.AddressRegion);
      setExternalApplyUrl(props.data.externalApplyUrl);
      setIsGovtJob(props.data.governmentJob == 1 ? true : false);

      // setLocations(props.data.)
      let str = props.data.location;
      let arr = str ? str.split(",") : [];
      setSelectedLocationsId(arr);
      let locationsNameArr = arr.map((item) => {
        let obj = props.locationList.find((o) => o.id === item);

        if (obj) return obj.locName;
      });

      setSelectedLocationsName(locationsNameArr);
      setStartDate(props.data.WalkinStartDate);
      setEndDate(props.data.WalkinEndDate);
      setAddress(props.data.WalkinAddress);

      setStoryPageUrl(
        `new-jobs/${removeSpacesWithHyphens(
          props.data.positionTitle
        )}/${removeSpacesWithHyphens(
          props.companyName
        )}/${removeSpacesWithHyphens(locationsNameArr[0])}/${props.data.id}`
      );

      if (props.data.WalkinTime) {
        let arr = props.data.WalkinTime.split("-");
        setWalkInStartTime(arr[0]);
        setWalkInEndTime(arr[1]);
      }

      if (props.webstorySummaryData.WebStoriesSummaryID) {
        getJobWebstoryDetailsByJobId();
      }
    }
  }, [props.show]);

  return (
    <>
      <Modal
        show={props.show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        // size="lg"
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Body>
          <div className={classes.d_flex}>
            <div className={classes.imgDiv}>
              <img src={adDetailImage} width="100%" height="100%" />
            </div>
            <form
              className={classes.formDiv}
              onSubmit={(e) => {
                e.preventDefault();
                updateAdsByPrintAdsId();
                if (props.webstorySummaryData.WebStoriesSummaryID) {
                  if (webstoryDetailUUID) {
                    updateJobWebstoryDetails();
                  } else {
                    addJobWebstoryDetails();
                  }
                }
              }}
            >
              <div className={classes.header}>
                <h4>Job Posting Details</h4>
                <ImCross onClick={handleClose} />
              </div>
              <div>
                {/* <div>
                  <label>Job Application Details</label>
                  <textarea
                    rows={"5"}
                    value={jobApplicationDetail}
                    onChange={(e) => {
                      setJobApplicationDetail(e.target.value);
                    }}
                  />
                </div> */}

                <div className={classes.formGroup}>
                  <label htmlFor="Content">Description :</label>

                  <div>
                    <TextEditor
                      onChangeContent={setJobApplicationDetail}
                      value={jobApplicationDetail}
                    />
                  </div>
                </div>

                <div>
                  <label>
                    Position Title<span>*</span>
                  </label>
                  <input
                    type="text"
                    value={positionTitle}
                    onChange={(e) => {
                      setPositionTitle(e.target.value);
                    }}
                    required
                  />
                </div>
                <div>
                  <label>
                    Job Function<span>*</span>
                  </label>
                  <select
                    value={jobFunction}
                    onChange={(e) => {
                      setJobFunction(e.target.value);
                    }}
                    required
                  >
                    <option value="" disabled={true} selected>
                      Choose the option
                    </option>
                    {props.jobFunctionList.map((item) => {
                      return <option value={item.id}>{item.jobName}</option>;
                    })}
                  </select>
                </div>
                <div>
                  <label>
                    Level<span>*</span>
                  </label>
                  <select
                    value={level}
                    onChange={(e) => {
                      setLevel(e.target.value);
                    }}
                    required
                  >
                    <option value="" disabled={true}>
                      Choose the option
                    </option>
                    {props.levelList.map((item) => {
                      return <option value={item.id}>{item.levelName}</option>;
                    })}
                  </select>
                </div>

                <div className={classes.formGroup}>
                  <label htmlFor="Content">Job Application Description :</label>

                  <div>
                    <TextEditor
                      onChangeContent={setJobDescription}
                      value={jobDescription}
                    />
                  </div>
                </div>
                {/* <div>
                  <label>
                    Job Description<span>*</span>
                  </label>
                  <textarea
                    rows={"5"}
                    value={jobDescription}
                    onChange={(e) => {
                      setJobDescription(e.target.value);
                    }}
                    required
                  />
                </div> */}
                <div>
                  <label>Min Salary</label>
                  <input
                    type="number"
                    value={minSalary}
                    onChange={(e) => {
                      setMinSalary(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>Max Salary</label>
                  <input
                    type="number"
                    value={maxSalary}
                    onChange={(e) => {
                      setMaxSalary(e.target.value);
                    }}
                  />
                </div>

                <div>
                  <label>Min Experience</label>
                  <input
                    type="text"
                    placeholder="optional"
                    value={minExp}
                    onChange={(e) => {
                      setMinExp(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>Max Experience</label>
                  <input
                    type="text"
                    placeholder="optional"
                    value={maxExp}
                    onChange={(e) => {
                      setMaxExp(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>Skills</label>
                  <input
                    type="text"
                    placeholder="optional"
                    value={skills}
                    onChange={(e) => {
                      setSkills(e.target.value);
                    }}
                  />
                </div>

                {minSalary || maxSalary ? (
                  <div>
                    <label>Currency</label>
                    <select
                      value={currency}
                      onChange={(e) => {
                        setCurrency(e.target.value);
                      }}
                    >
                      <option value="" disabled={true}>
                        Choose the option
                      </option>
                      {curr.map((item) => {
                        return <option value={item.cc}>{item.cc}</option>;
                      })}
                    </select>
                  </div>
                ) : (
                  <></>
                )}
                <div>
                  <label>Email</label>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>
                    Industry<span>*</span>
                  </label>
                  <select
                    value={industry}
                    onChange={(e) => {
                      setIndustry(e.target.value);
                    }}
                    required
                  >
                    <option value="">Choose the option</option>
                    {props.industryList.map((item) => {
                      return (
                        <option value={item.id}>{item.industryName}</option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <label>Employment Type</label>
                  <select
                    value={employementType}
                    onChange={(e) => {
                      setEmployementType(e.target.value);
                    }}
                  >
                    <option value="" disabled={true} selected>
                      Choose the option
                    </option>
                    {employmentList.map((item) => {
                      return <option value={item.type}>{item.type}</option>;
                    })}
                  </select>
                </div>
                <div>
                  <label>Postal Code</label>
                  <input
                    type="text"
                    value={postalCode}
                    onChange={(e) => {
                      setPostalCode(e.target.value);
                    }}
                  />
                </div>

                <div>
                  <label>Street Address</label>
                  <input
                    type="text"
                    value={streetAddress}
                    onChange={(e) => {
                      setStreetAddress(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>
                    Region<span>*</span>
                  </label>
                  <select
                    value={region}
                    onChange={(e) => {
                      setRegion(e.target.value);
                    }}
                    required
                  >
                    <option value="" disabled={true} selected>
                      Choose the option
                    </option>
                    {states.map((item) => {
                      return (
                        <option key={item.id} value={item.id}>
                          {item.code}-{item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
                <div>
                  <label>External Apply URL</label>
                  <input
                    type="text"
                    value={externalApplyUrl}
                    onChange={(e) => {
                      setExternalApplyUrl(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <span className={classes.note}>
                    (Please enter external URL from print ads if email is not
                    present)
                  </span>
                </div>
                <div>
                  <label>
                    Locations<span>*</span>
                  </label>
                  <select
                    onChange={(e) => {
                      findLocationIdHandler(e.target.value);
                      addLocationHandler(e.target.value);
                    }}
                  >
                    <option value="" disabled={true} selected>
                      Choose the option
                    </option>
                    {props.locationList.map((item, id) => {
                      return <option key={item.id}>{item.locName}</option>;
                    })}
                  </select>
                </div>

                <div className={classes.formGroup} style={{ position: "relative" }}>
                  <label htmlFor="seoUrl">SEO URL:</label>
                  <input
                    type="text"
                    id="seoUrl"
                    value={seoUrl}
                    onChange={(e) => handleSeoUrlChange(e.target.value)}
                    disabled={!isSeoUrlEditable}
                    required
                  />
                  {seoUrlValidationMessage && (
                    <div
                      className={
                        isSeoUrlValid
                          ? classes.successMessage
                          : classes.errorMessage
                      }
                    >
                      {seoUrlValidationMessage}
                    </div>
                  )}
                </div>

                <div className={classes.locationBoxWrapper}>
                  {selectedLocationsName.map((currentLocationValue, index) => {
                    return (
                      <div
                        key={index}
                        id={index}
                        className={classes.locationBox}
                      >
                        {currentLocationValue}
                        <AiOutlineClose
                          onClick={() => {
                            deleteLocationName(index);
                            deleteLocationId(index);
                          }}
                          className={classes.closeIcon}
                        />
                      </div>
                    );
                  })}
                </div>

                <div>
                  <EventInputs
                    input={"CheckBox"}
                    onChange={() => setIsGovtJob(!isGovtJob)}
                    label={"Government Job ?"}
                    value={isGovtJob}
                  />
                </div>

                <hr />
                <div>
                  <h5>Walk in Details</h5>
                </div>
                <div>
                  <label>Start Time</label>
                  <input
                    type="time"
                    value={walkInStartTime}
                    onChange={(e) => {
                      setWalkInStartTime(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>End Time</label>
                  <input
                    type="time"
                    value={walkInEndTime}
                    onChange={(e) => {
                      setWalkInEndTime(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>Start Date</label>
                  <input
                    type="date"
                    value={startDate}
                    onChange={(e) => {
                      setStartDate(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>End Date</label>
                  <input
                    type="date"
                    value={endDate}
                    onChange={(e) => {
                      setEndDate(e.target.value);
                    }}
                  />
                </div>
                <div>
                  <label>Address</label>
                  <textarea
                    rows={"5"}
                    value={address}
                    onChange={(e) => {
                      setAddress(e.target.value);
                    }}
                  />
                </div>
                <hr />
                <>
                  {props.webstorySummaryData.WebStoriesSummaryID && (
                    <>
                      <div>
                        <h5>Add Webstory Summary</h5>
                      </div>
                      <EventInputs
                        input={"CheckBox"}
                        onChange={() => setIsStoryLive(!isStoryLive)}
                        label={"Webstory Live ?"}
                        value={isStoryLive}
                      />
                      <div>
                        <label>
                          Summary ID<span>*</span>
                        </label>
                        <input
                          type="text"
                          value={props.webstorySummaryData.WebStoriesSummaryID}
                        // onChange={(e) => {
                        //   setStorySummaryId(e.target.value);
                        // }}
                        />
                      </div>
                      <div>
                        <label>
                          Company Name<span>*</span>
                        </label>
                        <input
                          type="text"
                          value={storyTitle}
                          onChange={(e) => {
                            setStoryTitle(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <label>
                          Position Title<span>*</span>
                        </label>
                        <input
                          type="text"
                          value={storyDescription}
                          onChange={(e) => {
                            setStoryDescription(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <label>Heading</label>
                        <input
                          type="text"
                          value={storyHeadingLast}
                          onChange={(e) => {
                            setStoryHeadingLast(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <label>
                          Job URL<span>*</span>
                        </label>
                        <input
                          type="text"
                          value={storyPageUrl}
                          onChange={(e) => {
                            setStoryPageUrl(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <label>Image Alt Tag</label>
                        <input
                          type="text"
                          value={storyImageAltTag}
                          onChange={(e) => {
                            setStoryImageAltTag(e.target.value);
                          }}
                        />
                      </div>
                      <div>
                        <label>Image</label>
                        <input
                          type="file"
                          accept=".png ,.jpeg,.jpg ,.webp"
                          onChange={(e) => {
                            setStoryImage(e.target.files[0]);
                          }}
                        />
                      </div>
                      {storyImage && (
                        // eslint-disable-next-line jsx-a11y/alt-text
                        <img
                          src={URL.createObjectURL(storyImage)}
                          className={classes.imgicon}
                        />
                      )}{" "}
                      {newStoryImage && !storyImage && (
                        <img
                          src={newStoryImage}
                          alt="icon"
                          className={classes.imgicon}
                        />
                      )}
                    </>
                  )}
                </>
                {/* <button
                  onClick={() => {
                    if (webstoryDetailUUID) {
                      updateJobWebstoryDetails();
                    } else {
                      addJobWebstoryDetails();
                    }
                  }}
                >
                  webstory
                </button> */}
                <div className={classes.buttons}>
                  {loading ? (
                    <Loader
                      type="TailSpin"
                      color="red"
                      height={30}
                      width={30}
                    />
                  ) : (
                    <button type="submit">Save Job</button>
                  )}
                </div>
              </div>
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                    <Button variant="primary">Understood</Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default EditJobModal;
