import React, { useState, useEffect } from "react";
import EventInputs from "../Events/EventInputs";
import Modal from "../Modal";
import classes from "./UpdateHr.module.css";
import { Link, useHistory, useParams } from "react-router-dom";
import Loader from "react-loader-spinner";
import BackButton from "../BackButton";
import TextEditor from "../TextEditor";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import { AiFillCopy, AiOutlineCloseCircle } from "react-icons/ai";

const UpdateHr = () => {
  const history = useHistory();
  const { id } = useParams();

  const [fullName, setFullName] = useState();
  const [passLoader, setPassLoader] = useState();
  const [category, setCategory] = useState();
  const [designation, setDesignation] = useState();
  const [yrsCurrentOrg, setYrsCurrentOrg] = useState();
  const [currentCompany, setCurrentCompany] = useState();
  const [location, setLocation] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [totalExperience, setTotalExperience] = useState();
  const [shortDescription, setShortDescription] = useState();
  const [areaOfExpertise, setAreaOfExpertise] = useState();
  const [workHistory, setWorkHistory] = useState();
  const [awardsAchievements, setAwardsAchievements] = useState();
  const [connectToTwitter, setConnectToTwitter] = useState();
  const [website, setWebsite] = useState();
  const [profilePicture, setProfilePicture] = useState();
  const [profilePicturePre, setProfilePicturePre] = useState();
  const [featuredMedia, setFeaturedMedia] = useState();
  const [editableImage, setEditableImage] = useState(true);
  const [check, setCheck] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [erorrshow, setErrorShow] = useState(false);
  const [internalErrorShow, setInternalErrorShow] = useState(false);
  const [payloadErrorShow, setPayloadErrorShow] = useState(false);
  const [position, setPosition] = useState();
  const [showImages, setShowImages] = useState([]);
  const [isFeatured, setIsFeatured] = useState(0)

  const HrCategory = [
    {
      type: "Free",
    },
    {
      type: "Paid",
    },
  ];

  useEffect(() => {
    viewHr();
  }, []);


  const revalidatePage = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    let path = "hrprofessional-profile/".includes("https://timesascent.com")
      ? "hrprofessional-profile/".split("https://timesascent.com")[1]
      : `/hrprofessional-profile/${id}`;



    fetch(
      `https://timesascent.com/api/revalidate?path=${path}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };


  const NewHr = () => {
    setPassLoader(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();

    if (fullName) formdata.append("FullName", fullName);
    if (designation) formdata.append("Designation", designation);
    if (yrsCurrentOrg) formdata.append("YrsCurrentOrg", yrsCurrentOrg);
    if (currentCompany) formdata.append("CurrentCompany", currentCompany);
    if (location) formdata.append("Location", location);
    if (totalExperience) formdata.append("TotalExperience ", totalExperience);
    if (shortDescription) formdata.append("ShortDescription", shortDescription);
    if (areaOfExpertise) formdata.append("AreaOfExpertise", areaOfExpertise);
    if (workHistory) formdata.append("WorkHistory", workHistory);
    if (awardsAchievements)
      formdata.append("AwardsAchievements", awardsAchievements);
    if (connectToTwitter) formdata.append("ConnectToTwitter", connectToTwitter);
    if (website) formdata.append("Website", website);
    if (profilePicture) formdata.append("ProfilePicture", profilePicture);
    if (position) formdata.append("Position ", position);
    if (category) formdata.append("Category ", category);
    if (startDate) formdata.append("StartDate ", startDate);
    if (endDate) formdata.append("EndDate ", endDate);
    // formdata.append("CurrentCompanyLogo", (currentCompanyLogo?currentCompanyLogo:""));
    if (featuredMedia) formdata.append("FeaturedMedia", featuredMedia);
    formdata.append("Id", id);
    formdata.append("IsPublished", check);
    formdata.append("IsFeatured ", isFeatured);

    // formdata.append("FullName", fullName);
    // formdata.append("Designation", designation);
    // formdata.append("YrsCurrentOrg", yrsCurrentOrg);
    // formdata.append("CurrentCompany", currentCompany);
    // formdata.append("Location", location);
    // formdata.append("TotalExperience ", totalExperience);
    // formdata.append("ShortDescription", shortDescription);
    // formdata.append("AreaOfExpertise", areaOfExpertise);
    // formdata.append("WorkHistory", workHistory);
    // formdata.append("AwardsAchievements", awardsAchievements);
    // formdata.append("ConnectToTwitter", connectToTwitter);
    // formdata.append("Website", website);
    // if (profilePicture)
    //   formdata.append("ProfilePicture", profilePicture);
    // formdata.append("FeaturedMedia", featuredMedia);
    // formdata.append("IsPublished", check);
    // formdata.append("Id", id);
    // formdata.append("Position ", position);
    // formdata.append("Category ", category);
    // formdata.append("StartDate ", startDate);
    // formdata.append("EndDate ", endDate);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };
    fetch(
      global.apiLink + "/v1/api/apiTimes/UpdateHRprofessional",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status === "SUCCESS" && result.status_code === 200) {
          revalidatePage()
          setModalShow(true);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setInternalErrorShow(true);
        } else if (result.status_code === 300) {
          setErrorShow(true);
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => {
        setPassLoader(false);
      });
  };
  const viewHr = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      Id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/v1/api/apiTimes/GetHRprofessionalById",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setProfilePicturePre(result.data.profilePicture);
        setFullName(result.data.fullName);
        setDesignation(result.data.designation);
        setCurrentCompany(result.data.currentCompany);
        setLocation(result.data.location);
        setShortDescription(result.data.shortDescription);
        setTotalExperience(result.data.totalExperience);
        setAreaOfExpertise(result.data.areaOfExpertise);
        setWorkHistory(result.data.workHistory);
        setAwardsAchievements(result.data.awardsAchievements);
        setConnectToTwitter(result.data.connectToTwitter);
        setFeaturedMedia(result.data.featuredMedia);
        setYrsCurrentOrg(result.data.yrsCurrentOrg);
        setWebsite(result.data.website);
        setPosition(result.data.position);
        setCategory(result.data.category);
        setStartDate(result.data.startdate);
        setEndDate(result.data.enddate);
        setCheck(result.data.isPublished);
        console.log("data:" + result.data.profilePicture);
      })
      .catch((error) => console.log("error", error));
  };
  const multipleImagesUpload = (e) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    if (e !== undefined) {
      for (let index = 0; index < e.target.files.length; index++) {
        formdata.append("upload", e.target.files[index]);
      }
    }

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      global.apiLink + '/adminapi/UploadHRProfessionalMultipleImages',
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          setShowImages(result.data);
        } else if (result.status_code === 400 || result.status_code === 500) {
          setPayloadErrorShow(true);
        } else if (result.status_code === 413) {
          setPayloadErrorShow(true);
        }
      })
      .catch((error) => { console.log("error", error); setPayloadErrorShow(true); });
  };

  useEffect(() => {
    multipleImagesUpload();
  }, []);

  const onBodyChangeHandler = (textEditorValue) => {
    setAreaOfExpertise(textEditorValue);
  };
  const onBodyHandler = (textEditorValue) => {
    setShortDescription(textEditorValue);
  };
  const onBodyHandlerAward = (textEditorValue) => {
    setAwardsAchievements(textEditorValue);
  };
  const onChangeWorkHistory = (textEditorValue) => {
    setWorkHistory(textEditorValue);
  };

  return (
    <div className={classes.top_div}>
      {modalShow && (
        <Modal
          heading={"Data updated successfully"}
          onClick={() => history.push("/BiDashboard/hrprofessionnal")}
        />
      )}
      {erorrshow && (
        <Modal
          heading={"Required Fields Missing"}
          onClick={() => setErrorShow(false)}
        />
      )}
      {internalErrorShow && (
        <Modal
          heading={"Internal server error"}
          onClick={() => setInternalErrorShow(false)}
        />
      )}

      {payloadErrorShow && (
        <Modal
          heading={"Image file is too large"}
          onClick={() => setPayloadErrorShow(false)}
        />
      )}

      <div className={classes.container}>
        <div className={classes.form}>
          <div className={classes.form_header_container}>
            <div className={classes.form_header}>Edit Hr Professional</div>
            <Link
              to="/BiDashboard/hrprofessionnal"
              className={classes.BackButton}
            >
              <BackButton />
            </Link>
          </div>
          <div className={classes.form_input}>
            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Full Name"}
                placeholder={"Full Name"}
                onChange={(e) => setFullName(e.target.value)}
                value={fullName}
              />

              <EventInputs
                input={"SingleLineInput"}
                label={"Location"}
                placeholder={"Location"}
                onChange={(e) => setLocation(e.target.value)}
                value={location}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Current Company"}
                placeholder={"Current Company"}
                onChange={(e) => setCurrentCompany(e.target.value)}
                value={currentCompany}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Designation"}
                placeholder={"Designation"}
                onChange={(e) => setDesignation(e.target.value)}
                value={designation}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Total Experience"}
                placeholder={"total Experience"}
                onChange={(e) => setTotalExperience(e.target.value)}
                value={totalExperience}
              />
              {/* <EventInputs
                input={"MultiLineInput"}
                label={"Short Description"}
                placeholder={"short Description"}
                onChange={(e) => setShortDescription(e.target.value)}
                value={shortDescription}
              /> */}
              <div className={classes.AddHrProfessional_Description}>
                Short Description
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                {/* <SunEditor
                  onChange={(e) => {
                    setShortDescription(e);
                  }}
                  value={shortDescription}
                  setContents={shortDescription}
                /> */}
                <div>
                  <TextEditor
                    onChangeContent={setShortDescription}
                    value={shortDescription}
                  />
                </div>
              </div>
              {/* <div className={classes.AddHrProfessional_Description}>Short Description</div>
              <div className={classes.textEditorWrapper}>
                <TextEditor onChange={onBodyHandler} value={shortDescription}/>
              </div> */}
              {/* <EventInputs
                input={"MultiLineInput"}
                label={"Area of Expertise"}
                placeholder={"area of Expertise"}
                onChange={(e) => setAreaOfExpertise(e.target.value)}
                value={areaOfExpertise}
              /> */}
              <div className={classes.AddHrProfessional_Description}>
                Area of Expertise
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                {/* <SunEditor
                  onChange={(e) => {
                    setAreaOfExpertise(e);
                  }}
                  value={areaOfExpertise}
                  setContents={areaOfExpertise}
                /> */}
                <div>
                  <TextEditor
                    onChangeContent={setAreaOfExpertise}
                    value={areaOfExpertise}
                  />
                </div>
              </div>
              {/* <div className={classes.AddHrProfessional_Description}>Area of Expertise</div>
              <div className={classes.textEditorWrapper}>
                <TextEditor onChange={onBodyChangeHandler} value={areaOfExpertise}/>
              </div> */}
              {editableImage && profilePicturePre ? (
                <div>
                  <div className={classes.fieldName}>
                    <label htmlFor="input7">Profile Picture</label>
                  </div>
                  <div className={classes.field1}>
                    <div className={classes.inputField}>
                      <div className={classes.inputFieldText}>
                        {" "}
                        <img
                          src={profilePicturePre}
                          alt=""
                          height={50}
                          width={50}
                        />
                      </div>
                      <div>
                        {" "}
                        <button
                          onClick={() => setEditableImage(false)}
                          className={classes.input_button}
                        >
                          edit{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <EventInputs
                  input={"ImageInput"}
                  label={"Profile Picture "}
                  placeholder={"profile Picture"}
                  onChange={(e) => setProfilePicture(e.target.files[0])}
                  type={"file"}
                  accept=".png, .jpg, .jpeg"
                />
              )}
              {/* <EventInputs
                input={"MultiLineInput"}
                label={"Featured Media"}
                placeholder={"Featured Meadia"}
                onChange={(e) => setFeaturedMedia(e.target.value)}
                value={featuredMedia}
              /> */}
              <div className={classes.AddHrProfessional_Description}>
                Featured Media
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                <div>
                  <TextEditor
                    onChangeContent={setFeaturedMedia}
                    value={featuredMedia}
                  />
                </div>
              </div>

            </div>

            <div className={classes.input}>
              <EventInputs
                input={"SingleLineInput"}
                label={"Twitter"}
                placeholder={"Twitter"}
                onChange={(e) => setConnectToTwitter(e.target.value)}
                value={connectToTwitter}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Position"}
                placeholder={"Position"}
                onChange={(e) => setPosition(e.target.value)}
                value={position}
              />
              <EventInputs
                input={"DateInput"}
                label={"Start Date"}
                placeholder={"Start Date"}
                onChange={(e) => setStartDate(e.target.value)}
                value={startDate}
              />
              <EventInputs
                input={"DateInput"}
                label={"End Date"}
                placeholder={"End Date"}
                onChange={(e) => setEndDate(e.target.value)}
                value={endDate}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Website"}
                placeholder={"Website"}
                onChange={(e) => setWebsite(e.target.value)}
                value={website}
              />
              <EventInputs
                input={"SingleLineInput"}
                label={"Years of Org"}
                placeholder={"Years of org"}
                onChange={(e) => setYrsCurrentOrg(e.target.value)}
                value={yrsCurrentOrg}
              />
              {/* <EventInputs
                input={"MultiLineInput"}
                label={"Awards Achievements"}
                placeholder={"Awards Achievements"}
                onChange={(e) => setAwardsAchievements(e.target.value)}
                value={awardsAchievements}
              /> */}
              <div className={classes.AddHrProfessional_Description}>
                Awards Achievements
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                <div>
                  <TextEditor
                    onChangeContent={setAwardsAchievements}
                    value={awardsAchievements}
                  />
                </div>
              </div>
              {/* <div className={classes.AddHrProfessional_Description}>Awards Achievements</div>
              <div className={classes.textEditorWrapper}>
                <TextEditor onChange={onBodyHandlerAward} value={awardsAchievements}/>
              </div> */}
              {/* <EventInputs
                input={"MultiLineInput"}
                label={"Work History"}
                placeholder={"Work History"}
                onChange={(e) => setWorkHistory(e.target.value)}
                value={workHistory}
              /> */}
              <div className={classes.AddHrProfessional_Description}>
                Work History
              </div>
              <div className={classes.AddHrProfessional_Desc}>
                {/* <SunEditor
                  onChange={(e) => {
                    setWorkHistory(e);
                  }}
                  value={workHistory}
                  setContents={workHistory}
                /> */}
                <div>
                  <TextEditor
                    onChangeContent={setWorkHistory}
                    value={workHistory}
                  />
                </div>
              </div>
              {/* <div className={classes.AddHrProfessional_Description}>Work History</div>
              <div className={classes.textEditorWrapper}>
                <TextEditor onChange={onChangeWorkHistory} value={workHistory}/>
              </div> */}

              <EventInputs
                input={"smalldropdown"}
                label={"Category"}
                placeholder={"Category"}
                onChange={(e) => setCategory(e.target.value)}
                displayData={HrCategory}
                value={category}
              />
            </div>
            <div className={classes.multipleImageContainer}>
              <div className={classes.formGroup}>
                <label htmlFor="image"> Multiple Image : </label>
                <input
                  multiple
                  type="file"
                  // required
                  accept=".png, .jpg, .jpeg"
                  name="image"
                  onChange={(e) => {
                    multipleImagesUpload(e);
                  }}
                />
              </div>

              <div className={classes.gridContainer}>
                {showImages.map((item, index) => (
                  <div className={classes.ImageUrlContainer}>
                    <img
                      src={item.ImageURL}
                      alt="hello"
                      className={classes.MultipleImgSelect}
                    />
                    <div
                      onClick={() => {
                        navigator.clipboard.writeText(item.ImageURL);
                        alert("Copied");
                      }}
                      className={classes.CopyURLIcon}
                    >
                      <AiFillCopy size={22} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className={classes.checkbox}>
              <EventInputs
                input={"CheckBox"}
                label={"Featured HR"}
                checked={isFeatured}
                // placeholder={"Years in current organization"}
                onChange={() => setIsFeatured(!isFeatured === true ? 1 : 0)}
              />
              <EventInputs
                input={"CheckBox"}
                onChange={() => setCheck(!check === true ? 1 : 0)}
                label={"Want to publish"}
                value={check}
              />
            </div>
          </div>
        </div>

        <button className={classes.Upload_button} onClick={() => NewHr()}>
          {passLoader ? (
            <Loader type="TailSpin" color="white" height={20} width={20} />
          ) : (
            <> Update</>
          )}
        </button>
      </div>
    </div>
  );
};

export default UpdateHr;
