import React, { useState, useEffect, useRef } from "react";
import classes from "./EditArticle.module.css";
import { useParams, useHistory } from "react-router-dom";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import Modal from "../Components/Articles/Modal";
import "suneditor/dist/css/suneditor.min.css";
import { AiOutlineCloseCircle, AiFillCopy } from "react-icons/ai";
import copy from "copy-to-clipboard";
import { AiOutlineClose } from "react-icons/ai";
import TextEditor from "../Components/TextEditor";
import EditingPage from "../Components/Main";
import LoadingSpinner from "../Components/LoadingSpinner";
import ErrorModal from "../Components/ErrorModal";

const EditArticle = () => {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [shortDescription, setShortDescription] = useState("");
  const [content, setContent] = useState("");
  const [content1, setContent1] = useState("");
  const [tags, setTags] = useState("");
  const [timeBaseUrl, setTimeBaseUrl] = useState("");
  const [imagePath, setImagePath] = useState("");
  const [videoLink, setVideoLink] = useState("");
  const [readCount, setReadCount] = useState("");
  const [category, setCategory] = useState([]);
  const [categoryID, setCategoryID] = useState([]);
  const [subCategory, setSubCategory] = useState("");
  const [subCategoryID, setSubCategoryID] = useState("");
  const [author, setAuthor] = useState("");
  const [data, setData] = useState([]);
  const [imageSelected, setImageSelected] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [isPickedByEditor, setIsPickedByEditor] = useState(0);
  const [isFeaturedArticle, setIsFeaturedArticle] = useState(0);
  const [isPublished, setIsPublished] = useState(false);
  const [paidArticle, setPaidArticle] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedImages, setSelectedImages] = useState([]);
  const [articleSource, setArticleSource] = useState("");
  const [categoryArticle, setCategoryArticle] = useState([]);
  const [pthumbimage, setPthumbimage] = useState("");
  const [thumbnail, setThumbnail] = useState("");
  const [loading, setLoading] = useState(false);
  // const [seoUrl, setSeoUrl] = useState("");
  const [seoUrl, setSeoUrl] = useState("");
  const [originalseoUrl, setoriginalseoUrlSeoUrl] = useState("");
  const [seoUrlValidationMessage, setSeoUrlValidationMessage] = useState("");
  const [isSeoUrlValid, setIsSeoUrlValid] = useState(false);
  const latestRequestId = useRef(0);
  const controllerRef = useRef(null);
  const [createdBy, setCreatedBy] = useState("");
  const [updatedBy, setUpdatedBy] = useState("");
  const [erorrshow, setErrorShow] = useState(false);

  // const [chooseArticlePage, setChooseArticlePage] = useState("");

  let fullImagePath = timeBaseUrl + imagePath;
  const handleSeoUrlValidation = async (value) => {
    const requestId = ++latestRequestId.current;

    if (controllerRef.current) {
      controllerRef.current.abort();
    }
    controllerRef.current = new AbortController();

    if (!value) {
      setSeoUrlValidationMessage("SEO URL cannot be empty.");
      setIsSeoUrlValid(false);
      return;
    }

    const formattedSeoUrl = value;
    const requestBody = { SeoURL: formattedSeoUrl };
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: "Token 77927b69bb144b065ca11bf2a9d452819cd852db",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
      signal: controllerRef.current.signal,
    };

    try {
      const response = await fetch(
        process.env.REACT_APP_API_URL + "/adminapi/CheckSeoURLinArticles",
        requestOptions
      );
      const result = await response.json();

      if (requestId === latestRequestId.current) {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          if (originalseoUrl === value) {
            setIsSeoUrlValid(true);
            setSeoUrlValidationMessage("SEO URL is correct.");
          }
          else if (result.IsSeoURLExist) {
            setSeoUrlValidationMessage("SEO URL already exists.");
          } else {
            setIsSeoUrlValid(true);
            setSeoUrlValidationMessage("SEO URL is correct.");
          }
        } else if (result.message === "Invalid SeoURL Format") {
          setIsSeoUrlValid(false);
          setSeoUrlValidationMessage("Invalid SeoURL. Use lowercase letters, numbers, starts with articles/ and single dashes");
        }
        else {
          setIsSeoUrlValid(false);
          setSeoUrlValidationMessage("Invalid SEO URL.");
        }
      }
    } catch (error) {
      if (error.name !== "AbortError" && requestId === latestRequestId.current) {
        setSeoUrlValidationMessage("Error validating SEO URL.");
      }
    }
  };

  const handleSeoUrlChange = (value) => {
    setSeoUrl(value);
    handleSeoUrlValidation(value);
  };

  const history = useHistory();
  // console.log(isPublished, "mobthumb", pthumbimage);
  useEffect(() => {
    if (sessionStorage.getItem("googleBiEmail")) {
      setUpdatedBy(sessionStorage.getItem("googleBiEmail"));
    }
  }, [sessionStorage.getItem("googleBiEmail")]);

  useEffect(() => {
    MultipleImage();
  }, [selectedImages]);

  const articlesource = [
    {
      type: "FTP",
    },
    {
      type: "Interns",
    },
    {
      type: "Rozgaar",
    },
    {
      type: "TA Team",
    },
  ];

  // const articlepage = [
  //   {
  //     type: "Course"
  //   },
  //   {
  //     type: "Event"
  //   },
  //   {
  //     type: "Jobs"
  //   },
  //   {
  //     type: "HR Professionals"
  //   }
  // ]

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const onModalCloseHandler = () => {
    setIsModalOpen(false);
    history.push("/BiDashboard/articles");
  };

  useEffect(() => {
    getCategoryApi();
  }, []);

  useEffect(() => {
    categoryList.map((item) => {
      if (
        category.includes(item.Category) &&
        !categoryID.includes(item.ArticleId)
      ) {
        setCategoryID((prev) => [...prev, item.ArticleId]);
      }
    });
  }, [category]);

  useEffect(() => {
    subCategoryList.map((item) => {
      return (
        item.subCategory === subCategory && setSubCategoryID(item.ArticleId)
      );
    });
  }, [subCategory]);

  useEffect(() => {
    let temp = [];
    categoryList.map((item) => {
      if (categoryArticle.includes(parseInt(item.ArticleId)))
        temp.push(item.Category);
    });
    setCategory(temp);
  }, [categoryArticle]);

  const removeTags = (str) => {
    let strippedString = "";
    if (str === null || str === "") return false;
    else strippedString = str.toString();
    strippedString = content.replace(/(<([^>]+)>)/gi, "");
    return strippedString.trim();
  };

  const getCategoryApi = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");
    var raw = JSON.stringify({});
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/article-category", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          setCategoryList(result.Article);
          setSubCategoryList(result.Category);
          getDetailApi();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const getDetailApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/view-article",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          setTitle(result.data.title);
          setShortDescription(result.data.shortDescription);
          setTimeBaseUrl(result.data.times_base_url);
          setImagePath(result.data.imagePAth);
          setThumbnail(result.data.mobileThumb);
          setVideoLink(result.data.videoLink);
          setReadCount(result.data.readCount);
          setSubCategory(result.data.subCategory);
          setIsPickedByEditor(result.data.isPickedByEditor);
          setIsFeaturedArticle(result.data.isFeaturedArticle);
          setIsPublished(result.data.isPublished == 1 ? true : false);
          setPaidArticle(result.data.paidArticle == "1" ? true : false);
          setAuthor(result.data.author);
          setContent(result.data.content);
          setTags(result.data.Tags);
          setArticleSource(result.data.articleSource);
          setSeoUrl(result.data.SeoURL);
          setoriginalseoUrlSeoUrl(result.data.SeoURL)
          setCreatedBy(result.data.createdBy);
          setCategoryArticle(
            result.articleCategory.map((item) => item.category)
          );
          const contentBlock = htmlToDraft(result.data.content);
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);

          setEditorState(editorState);
        }

        // console.log(result, "testingvideoLink");
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const revalidatePage = () => {
    const requestOptions = {
      method: "GET",
      redirect: "follow",
    };

    let path = seoUrl.includes("https://timesascent.com")
      ? seoUrl.split("https://timesascent.com")[1]
      : `/${seoUrl}`;



    fetch(
      `https://timesascent.com/api/revalidate?path=${path}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  console.log(seoUrl, "vsvsuyushsbigsvb")


  const editArticleAPI = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("id", id);
    formdata.append("title", title);
    formdata.append("shortdescription", shortDescription);
    formdata.append("content", content);
    formdata.append("tags", tags);
    formdata.append("videourl", null);
    formdata.append("seourl", seoUrl);
    formdata.append("pickedby", isPickedByEditor);
    formdata.append("featuredarticle", isFeaturedArticle);
    formdata.append("isPublished", isPublished ? "1" : "0");
    formdata.append("readcount", readCount);
    formdata.append("category", categoryID);
    formdata.append("subcategory", subCategoryID);
    formdata.append("author", author);
    formdata.append("articleSource", articleSource);
    formdata.append("paidArticle", paidArticle ? "1" : "0");
    formdata.append("createdBy", createdBy);
    formdata.append("updatedBy", updatedBy);
    imageSelected && formdata.append("imageurl", imageSelected);
    pthumbimage && formdata.append("mobilethumb", pthumbimage);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_API_URL + "/adminapi/edit-article",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          setIsModalOpen(true);
          revalidatePage()
          // history.push("/BiDashboard/articles");
        }
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };

  const MultipleImage = (e) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    if (e !== undefined) {
      for (let index = 0; index < e.target.files.length; index++) {
        formdata.append("upload", e.target.files[index]);
      }
    }
    formdata.append("articleId", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/uploadArticlesMultiple", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200) {
          GetMultipleImage();
        }
      })
      .catch((error) => console.log("error", error));
  };

  useEffect(() => {
    GetMultipleImage();
  }, []);

  const GetMultipleImage = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );

    var formdata = new FormData();
    formdata.append("articleId", id);

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formdata,
      redirect: "follow",
    };

    fetch(
      global.apiLink + "/adminapi/getArticlesMultipleImages",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result.data);
      })
      .catch((error) => console.log("error", error));
  };

  const RemoveMultipleImage = (id) => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/removeImageFromArticles", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          alert("Image Remove Successfully");
          GetMultipleImage();
        }
      })
      .catch((error) => console.log("error", error));
  };

  const RemoveCategory = (value) => {
    let obj = categoryList.find((o) => o.Category === value);
    setCategory(category.filter((item) => item !== value));
    setCategoryID(categoryID.filter((item) => item !== obj.ArticleId));

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      categoryId: obj.ArticleId,
      articleId: id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(global.apiLink + "/adminapi/deleteArticleCategory", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (result.status_code === 200 && result.status === "SUCCESS") {
          alert("Delete selected category!");
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <React.Fragment>
      {erorrshow && (
        <ErrorModal
          heading={"Please provide a valid SEO URL"}
          onClick={() => setErrorShow(false)}
        />
      )}
      <div className={classes.NewArticle}>
        <h3 className={classes.ArticleHeading}>Edit Article</h3>
        <h4 className={classes.ArticleHeading}>Article ID : {id}</h4>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            editArticleAPI();
            // if (isSeoUrlValid) {
            //   editArticleAPI();
            // }
            // else {
            //   setErrorShow(true)
            // }
          }}
        >
          <div className={classes.FormContainer}>
            <div className={classes.leftSide}>
              <div className={classes.formGroup}>
                <label htmlFor="title">Title : </label>
                <input
                  type="text"
                  id="title"
                  name="title"
                  placeholder="Title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="shortDescription">
                  Short Description : ( Length : {shortDescription.length}){" "}
                </label>
                <textarea
                  rows="4"
                  cols="50"
                  id="shortDescription"
                  name="shortDescription"
                  placeholder="Write Description"
                  onChange={(e) => setShortDescription(e.target.value)}
                  value={shortDescription}
                />
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="Content">
                  Content : ( Length : {removeTags(content).length})
                </label>

                <div>
                  <TextEditor onChangeContent={setContent} value={content} />
                </div>
              </div>

              <div className={classes.submitDiv}>
                <button type="submit" className={classes.submitButton}>
                  Update
                </button>
              </div>
            </div>
            <div className={classes.rightSide}>
              <h5 className={classes.otherDetailHeading}>Other Details</h5>

              <div className={classes.formGroup}>
                <label htmlFor="image">Image : </label>
                <input
                  type="file"
                  name="image"
                  onChange={(e) => {
                    setImageSelected(e.target.files[0]);
                  }}
                />
                {!imageSelected && (
                  <img
                    className={classes.articleImage}
                    src={fullImagePath}
                    alt="Article Main"
                  />
                )}
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="image">Upload Multiple Image : </label>

                <input
                  type="file"
                  name="image"
                  id="image"
                  multiple
                  onChange={(e) => MultipleImage(e)}
                  className={classes.articleImage}
                />

                <div className={classes.MapImg}>
                  {data.map((item, index) => (
                    <div className={classes.ImageUrlContainer}>
                      <img
                        src={item.imageURL}
                        alt="hello"
                        className={classes.MultipleImgSelect}
                      />
                      <AiOutlineCloseCircle
                        className={classes.RemoveImgIcon}
                        onClick={() => RemoveMultipleImage(item.id)}
                      />
                      <div
                        onClick={() => {
                          copy(item.imageURL);
                          alert("Copied");
                        }}
                        className={classes.CopyURLIcon}
                      >
                        <AiFillCopy size={22} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="image">Mobile Thumb : </label>
                <input
                  type="file"
                  name="image"
                  onChange={(e) => {
                    setPthumbimage(e.target.files[0]);
                    setThumbnail("");
                  }}
                />

                <div>
                  {thumbnail && !pthumbimage && (
                    // eslint-disable-next-line jsx-a11y/alt-text
                    <img
                      src={thumbnail}
                      alt="icon"
                      className={classes.articleImage}
                    />
                  )}
                  {pthumbimage && !thumbnail && (
                    <img
                      src={URL.createObjectURL(pthumbimage)}
                      className={classes.articleImage}
                    />
                  )}
                </div>
              </div>

              {/* <div className={classes.formGroup}>
                <label htmlFor="video">Video : </label>
                <input
                  type="text"
                  name="video"
                  value={videoLink}
                  placeholder="Youtube URL"
                  onChange={(e) => setVideoLink(e.target.value)}
                />
              </div> */}

              <div className={classes.formGroup}>
                <label htmlFor="tags">Tags : </label>
                <input
                  type="text"
                  name="tags"
                  placeholder="Tags"
                  value={tags}
                  onChange={(e) => setTags(e.target.value)}
                />
              </div>

              <div className={classes.formGroup}>
                <label for="cars">Category : </label>
                <select
                  name="category"
                  onChange={(e) => {
                    setCategory((prev) => {
                      return [...prev, e.target.value];
                    });
                  }}
                >
                  <>
                    <option value="">--Select category--</option>
                  </>
                  {categoryList.map((item) => {
                    return category.includes(item.Category) ? null : (
                      <option
                        value={item.Category}
                        selected={category === item.Category}
                      >
                        {item.Category}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className={classes.Category_map}>
                {category.map((item) => {
                  return (
                    <div className={classes.CategoryMap}>
                      {item}
                      <div onClick={() => RemoveCategory(item)}>
                        <AiOutlineClose />
                      </div>
                    </div>
                  );
                })}
              </div>

              <div className={classes.formGroup}>
                <label for="cars">Article Source : </label>
                <select
                  name="articleSource"
                  onChange={(e) => setArticleSource(e.target.value)}
                >
                  {articlesource.map((item) => {
                    return (
                      <option
                        value={item.type}
                        selected={articleSource === item.type}
                      >
                        {item.type}
                      </option>
                    );
                  })}
                </select>
              </div>

              <div className={classes.formGroup}>
                <label for="cars">Sub Category : </label>
                <select
                  name="subCategory"
                  onChange={(e) => setSubCategory(e.target.value)}
                >
                  {subCategoryList.map((item) => {
                    return (
                      <option
                        value={item.subCategory}
                        selected={subCategory === item.subCategory}
                      >
                        {item.subCategory}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* <div className={classes.formGroup}>
                <label for="cars">Choose Article Page : </label>
                <select
                  name="articlepage"
                  onChange={(e) => setChooseArticlePage(e.target.value)}
                >
                  {articlepage.map((item) => {
                    return (
                      <option
                        value={item.type}
                        selected={chooseArticlePage === item.type}
                      >
                        {item.type}
                      </option>
                    );
                  })}
                </select>
              </div> */}

              <div className={classes.formGroup}>
                <label htmlFor="author">Author : </label>
                <input
                  type="text"
                  name="author"
                  value={author}
                  placeholder="Author"
                  onChange={(e) => setAuthor(e.target.value)}
                />
              </div>
              {/* <div className={classes.formGroup}>
                <label htmlFor="seoUrl">SEO URL : </label>
                <input
                  type="text"
                  name="seoUrl"
                  value={seoUrl}
                  placeholder="articles/article-title/{uuid}"
                  onChange={(e) => setSeoUrl(e.target.value)}
                />
              </div> */}
              <div className={classes.formGroup} style={{ position: "relative" }}>
                <label htmlFor="seoUrl">SEO URL:</label>
                <input
                  type="text"
                  id="seoUrl"
                  name="seoUrl"
                  value={seoUrl}
                  onChange={(e) => handleSeoUrlChange(e.target.value)}
                  placeholder="articles/article-title"
                />
                {seoUrlValidationMessage && (
                  <div
                    className={`${classes.ValidationTooltip} ${isSeoUrlValid ? classes.successMessage : classes.errorMessage
                      }`}
                  >
                    {seoUrlValidationMessage}
                  </div>
                )}
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="createdBy">Created By : </label>
                <input
                  type="text"
                  name="createdBy"
                  disabled={true}
                  value={createdBy}
                  placeholder="Created By"
                  onChange={(e) => setCreatedBy(e.target.value)}
                />
              </div>

              <div className={classes.formGroup}>
                <label htmlFor="updatedBy">Updated By : </label>
                <input
                  type="text"
                  name="updatedBy"
                  disabled={true}
                  value={updatedBy}
                  placeholder="Updated By"
                  onChange={(e) => setUpdatedBy(e.target.value)}
                />
              </div>
              <div className={classes.CheckBoxStyle}>
                <div>
                  <div className={classes.formGroup}>
                    <div className={classes.checkBoxGroup}>
                      <label htmlFor="pickedBy">Paid Article : </label>
                      <input
                        type="checkbox"
                        name="PickedBy"
                        checked={paidArticle}
                        onChange={() => setPaidArticle(!paidArticle)}
                      />
                    </div>
                  </div>
                  <div className={classes.formGroup}>
                    <div className={classes.checkBoxGroup}>
                      <label htmlFor="pickedBy">Editor's Pick : </label>
                      <input
                        type="checkbox"
                        name="PickedBy"
                        checked={isPickedByEditor}
                        onChange={() =>
                          setIsPickedByEditor(!isPickedByEditor ? 1 : 0)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className={classes.formGroup}>
                    <div className={classes.checkBoxGroup}>
                      <label htmlFor="featuredArticle">
                        Featured Article :{" "}
                      </label>
                      <input
                        type="checkbox"
                        name="PickedBy"
                        checked={isFeaturedArticle}
                        onChange={() =>
                          setIsFeaturedArticle(!isFeaturedArticle ? 1 : 0)
                        }
                      />
                    </div>
                  </div>
                  <div className={classes.formGroup}>
                    <div className={classes.checkBoxGroup}>
                      <label htmlFor="featuredArticle">Live : </label>
                      <input
                        type="checkbox"
                        name="PickedBy"
                        checked={isPublished}
                        onChange={() => setIsPublished(!isPublished)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className={classes.formGroup}>
                <label htmlFor="pickedBy">@Times Ascent</label>
              </div>
            </div>
          </div>
        </form>
        <Modal isModalOpen={isModalOpen} onClose={onModalCloseHandler} />
        {loading && (
          <div className={classes.loaderContainer}>
            <LoadingSpinner />
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default EditArticle;
